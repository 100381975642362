



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: OpenSans-Regular;
  src: url('../fonts/IRANSansWeb_Light.ttf'); 
}

@font-face {
  font-family: OpenSans-Bold;
  src: url('../fonts/IRANSansWeb_Medium.ttf'); 
}




/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

/* * {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
} */

/* body, html {
	height: 100%;
	font-family: OpenSans-Regular, sans-serif;
} */

/*---------------------------------------------*/
/* a {
	font-family: OpenSans-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
} */

/* a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
  color: #fff;
} */

/*---------------------------------------------*/

/* p {
	font-family: OpenSans-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
} */

p {
  margin: 0px;
}

/* h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-family: OpenSans-Regular;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}


ul, li {
	margin: 0px;
	list-style-type: none;
} */


/*---------------------------------------------*/
.input100 {
	outline: none;
	border: none;
}

/* textarea {
  outline: none;
  border: none;
} */

.input100:focus {
  border-color: transparent !important;
}

.login100:focus::-webkit-input-placeholder { color:transparent; }
.login100:focus:-moz-placeholder { color:transparent; }
.login100:focus::-moz-placeholder { color:transparent; }
.login100:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

.login100::-webkit-input-placeholder { color: #999999;}
.login100:-moz-placeholder { color: #999999;}
.login100::-moz-placeholder { color: #999999;}
.login100:-ms-input-placeholder { color: #999999;}

textarea::-webkit-input-placeholder { color: #999999;}
textarea:-moz-placeholder { color: #999999;}
textarea::-moz-placeholder { color: #999999;}
textarea:-ms-input-placeholder { color: #999999;}


/* label {
  display: block;
  margin: 0;
} */

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: OpenSans-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #cccccc;
}



/*//////////////////////////////////////////////////////////////////
[ login ]*/
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background: #2575fc;
  background: -webkit-linear-gradient(left, #6a11cb, #20abc9);
  background: -o-linear-gradient(left, #6a11cb, #20abc9);
  background: -moz-linear-gradient(left, #6a11cb, #20abc9);
  background: linear-gradient(left, #6a11cb, #20abc9);
  background-image: linear-gradient(#72edf2, #5151e5);

}

.gradientBack{
    background-image: linear-gradient(#5151e5, #6a11cb);
}


.sidebar[data-color="blue"]:after,
.off-canvas-sidebar[data-color="blue"]:after {
  background: #ecf4fb;
 }
 .sidebar[data-color="blue"] .nav li a,
  .sidebar[data-color="blue"] .nav li a i,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"],
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] i,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a,
  .off-canvas-sidebar[data-color="blue"] .nav li a,
  .off-canvas-sidebar[data-color="blue"] .nav li a i,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"],
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] i,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="blue"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a {
    color: #000;
    opacity: .9; }
  .sidebar[data-color="blue"] .nav li:hover:not(.active) > a,
  .sidebar[data-color="blue"] .nav li:focus:not(.active) > a,
  .off-canvas-sidebar[data-color="blue"] .nav li:hover:not(.active) > a,
  .off-canvas-sidebar[data-color="blue"] .nav li:focus:not(.active) > a {
    opacity: 1; }
  .sidebar[data-color="blue"] .logo .simple-text,
  .off-canvas-sidebar[data-color="blue"] .logo .simple-text {
    color: #000; }
  .sidebar[data-color="blue"] .logo:after,
  .off-canvas-sidebar[data-color="blue"] .logo:after {
    background-color: #000;
    opacity: .9; }
  .sidebar[data-color="blue"] .user .info a span,
  .sidebar[data-color="blue"] .user .nav .sidebar-mini-icon,
  .sidebar[data-color="blue"] .user .nav .sidebar-normal,
  .off-canvas-sidebar[data-color="blue"] .user .info a span,
  .off-canvas-sidebar[data-color="blue"] .user .nav .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="blue"] .user .nav .sidebar-normal {
    color: #000 !important; }
  .sidebar[data-color="blue"] .user:after,
  .off-canvas-sidebar[data-color="blue"] .user:after {
    background-color: #000;
    opacity: .8; }




 .sidebar[data-active-color="blue"] .nav li.active > a,
  .sidebar[data-active-color="blue"] .nav li.active > a i,
  .sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"],
  .sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] i,
  .sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon,
  .sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a,
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a,
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a i,
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"],
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] i,
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon,
  .off-canvas-sidebar[data-active-color="blue"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
    color: #2f70f6;
    opacity: 1; }




    .sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before,
  .sidebar .sidebar-wrapper li.active > [data-toggle="collapse"] + div .nav li:before {
    border-right: 17px solid #DDDDDD;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: -16px;
    opacity: 0;
    top: 7px;
    transition: opacity 150ms ease-in; }

    .sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after,
    .sidebar .sidebar-wrapper li.active > [data-toggle="collapse"] + div .nav li:after {
      border-right: 17px solid #f4f8f9;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      right: -17px;
      opacity: 1;
      top: 7px;
      transition: opacity 150ms ease-in; }


.wrap-login100 {
  width: 560px;
}


/*==================================================================
[ Form ]*/

.login100-form {
  width: 100%;
  background-color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.login100-form-title {
  width: 100%;
  display: block;
  font-family: OpenSans-Regular;
  font-size: 30px;
  color: #fefefe;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 43px;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 50%;
  height: 75px;
  position: relative;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  background-color: #fff;
}

.wrap-input100.rs1 {
  border-top-left-radius: 12px;
  border-right: none;
}

.wrap-input100.rs2 {
  border-top-right-radius: 12px;
}

.label-input100 {
  font-family: OpenSans-Bold;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;

  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 30px;
  left: 0;
  top: 28px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100 {
  font-family: OpenSans-Bold;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  background: transparent;
  padding: 0 30px;
}

.input100::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

input.input100 {
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}


.input100:focus {
  height: 55px;
}

.input100:focus + .label-input100 {
  top: 10px;
  font-size: 13px;
  color: #111111;
}

.has-val {
  height: 55px !important;
}

.has-val + .label-input100 {
  top: 10px;
  font-size: 13px;
  color: #111111;
}



/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-family: OpenSans-Bold;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 70px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
  background: #111111;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;

  background: #2575fc;
  background: -webkit-linear-gradient(right, #6a11cb, #2575fc);
  background: -o-linear-gradient(right, #6a11cb, #2575fc);
  background: -moz-linear-gradient(right, #6a11cb, #2575fc);
  background: linear-gradient(right, #6a11cb, #2575fc);
  background-image: linear-gradient(to right, #202020, #1e2ad2);


  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: transparent;
}
.login100-form-btn:hover:before {
  opacity: 1;
}



/*------------------------------------------------------------------
[ Responsive ]*/


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: OpenSans-Bold;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}



/*//////////////////////////////////////////////////////////////////
[ Responsive ]*/
@media (max-width: 576px) {
  .wrap-input100 {
    width: 100%;
  }

  .wrap-input100.rs1 {
    border-top-right-radius: 12px;
    border-right: 1px solid #e0e0e0;
  }

  .wrap-input100.rs2 {
    border-top-right-radius: 0px;
  }
}