.box {
    background-color: #fff;
    border-radius: 8px;
    border: 0px solid #f7f7f7;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
    /* overflow: hidden; */
    /* padding-bottom: 100px; */
}

.no-outline:focus {
    outline: none;
}

.sticky-position {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
}

input:focus {
    border-color: #eee !important;
}

.no-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}

select:invalid {
    color: gray;
}

.required::after {
    content: "*";
    color: #ee5050;
    padding: 2px;
}

.blurBack {
    filter: blur(15px);
}

.checkContainer {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 22px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.5s;
}


/* Hide the browser's default checkbox */

.checkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 11px;
    background-color: #eee;
    transition: all 0.3s;
}


/* On mouse-over, add a grey background color */

.checkContainer:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.checkContainer input:checked~.checkmark {
    background-color: #2f70f6;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.checkContainer input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.checkContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

::-moz-placeholder {
    color: rgb(220, 220, 220);
}

:-ms-input-placeholder {
    color: rgb(220, 220, 220);
}

::-webkit-input-placeholder {
    color: rgb(220, 220, 220);
}

input[type="checkbox"] {
    --height: 30px;
    --inset: 0.25rem;
    --border-size: 3px;
    --color-hover: #dcdcdc;
    --color-bg: hsl(0, 0%, 64%);
    --color-bg-checked: #007aff;
    --color-knob: #fff;
    --knob-size: calc(var(--height) - var(--inset) * 2);
    --width: calc(var(--height) * 2 - var(--inset) * 2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    width: var(--width);
    height: var(--height);
    border-radius: var(--height);
    border: var(--border-size) solid transparent;
    transition: background 0.3s ease;
    overflow: hidden;
}

input[type="checkbox"]:focus {
    outline: none;
}

input[type="checkbox"]:hover,
input[type="checkbox"]:focus {
    background: var(--color-hover);
}

input[type="checkbox"]:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-bg);
    transition: background 0.3s ease;
}

input[type="checkbox"]:after {
    content: "";
    position: absolute;
    border-radius: 100%;
    top: calc(var(--inset));
    left: calc(var(--inset));
    width: var(--knob-size);
    height: var(--knob-size);
    background: var(--color-knob);
    transition: transform 0.3s ease;
}

input[type="checkbox"]:checked:before {
    background: var(--color-bg-checked);
}

input[type="checkbox"]:checked:after {
    transform: translateX(100%);
}

.center-marker {
    width: 25px;
    height: 43px;
    margin-top: -43px;
    margin-left: -12.5px;
    top: 50%;
    left: 50%;
    position: absolute;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.marker {
    width: 25px;
    height: 43px;
    filter: grayscale(100%);
    top: 0;
    left: 0;
    position: absolute;
    background-size: cover;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.mapboxgl-ctrl-attrib-inner {
    display: none !important;
}

.mapboxgl-canvas {
    position: relative;
}

canvas {
    outline: none;
}